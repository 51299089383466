<template>
    <div class="content-wrap clearfix">
        <div class="entry entry-page">
            <h2 class="entry-title">{{ header }}</h2>
            <div class="entry-content">
                <p class="mb-6" v-html="desc"></p>
                <div class="tab-container">
                    <ul class="tabs clearfix mb-6">
                        <template v-for="(tab, index) in tabs" :key="index">
                            <li @click="$emit('change-tab', index)">
                                <a href="javascript:void(0)"
                                    :class="{ 'tab-link-active': index == selectedTabId }">{{ tab.label }}</a>
                            </li>
                        </template>
                    </ul>
                    <div class="tab-content tab-active mt-2">
                        <div v-if="products.length" v-for="(item, itemIndex) in products" :key="itemIndex"
                            class="dinning-menu-item">
                            <div class="image">
                                <a @click="() => actions.selectPreviewImage(item?.img)" data-fancybox="breakfast-gallery"
                                    data-caption="Kumsi Tea - Rose green tea, Anastasia, The vert au iasmin">
                                    <img :src="item.img" alt="">
                                    <span class="mdi mdi-eye"></span>
                                </a>
                            </div>
                            <div class="text overflow-hidden">
                                <strong>{{ item.title }}</strong>
                                <p class="text-overflow-ellipsis" v-html="item.desc" />
                            </div>
                            <div class="price">
                                <strong>${{ item.price }}</strong>
                                <p>per {{ item.price }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CopyRight v-bind="footerProps" class="mb-28"/>
        
    </div>
</template>

<script>
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        CopyRight,
    },
    props: {
        header: {
            type: String,
            default: ""
        },
        desc: {
            type: String,
            default: ""
        },
        tabs: {
            type: Array,
            default: () => []
        },
        products: {
            type: Array,
            default: () => []
        },
        selectedTabId: {
            type: String,
            default: "0"
        }
    },
    data() {
        return {
            footerProps,
            isShowPreview: false,
        }
    },
}
</script>

<style></style>