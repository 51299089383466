<template>
    <div>
       <video ref="videoJsPlayer" class="video-js vjs-defaultskin"></video>
    </div>
 </template>
 <script>
    // Importing video-js
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css';
    export default {
       props: {
          options: {
             type: Object,
             default() {
                return {};
             }
          }
       },
       data() {
            return {
                player: null
            }
    },
    // initializing the video player
    // when the component is being mounted
    mounted() {
       this.player = videojs(this.$refs.videoJsPlayer, this.options,() => {
          this.player.log('video player ready', this);
       });
       this.$refs.videoJsPlayer.player.controlBar.progressControl.disable();
    },
    // destroying the video player
    // when the component is being destroyed
    beforeDestroy() {
       if (this.player) {
          this.player.dispose();
       }
    }
 }
 </script>