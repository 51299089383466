<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>    
    <DinningTab v-show="!mediaInfo.isFullScreen" v-bind="tabInfo" @change-tab="(tabIndex) => $emit('change-tab', tabIndex)" />
    <BookSection v-bind="bookProps" />
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import BookSection from '../../organisms/BookingForm/BookingForm.vue';
import DinningTab from '../../organisms/DinningTab/DinningTab.vue';
import VideoBox from '../../molecules/Video/Video.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';

/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, BookSection, SocialBox, DinningTab, VideoBox, Carousel
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        tabInfo: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        bookProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            videoOpt: {
                autoplay: true,
                controls: false,
                preload: true,
                fluid: false,
                loop: true,
                sources: [
                    {
                    src: this.mediaInfo.video,
                    type: 'video/mp4'
                    }
                ],
            }
        }
    },
}
</script>

<style>
</style>