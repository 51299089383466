<template>
  <DinningTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :mediaInfo="mediaInfo"
    :tabInfo="tabInfo"
    :bookProps="bookProps"
    @change-tab="onChangeTab"
  />
  <div v-if="state.selectedImage" class="fixed inset-0" style="zIndex: 10000; background: #000000e6">
    <div class="w-full h-full flex justify-center items-center">
      <a @click="actions.selectPreviewImage(null)" class="absolute right-10 top-10 z-20 bg-white w-10 h-10 rounded-full flex justify-center items-center">
        <Icon name="XIcon" isOutline color="black" size="5"/>
      </a>
      <img :src="state.selectedImage" class="w-full h-full object-contain"/>
    </div>
  </div>
</template>

<script>
import DinningTemplate from "@/components/templates/Dinning/Dinning";
import { headerProps, socialProps } from "@/components/constants.js";
import moment from "moment";
import Icon from '@/components/atoms/Icons/Icons'

export default {
  components: {
    DinningTemplate,
    Icon,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      bookProps: {
        checkInDate: moment().add(1, 'days').set('hour', 13),
        checkOutDate: new Date("2018-11-21"),
      },
      mediaInfo: [
        {
          image: "https://starlight-media.s3.us-west-2.amazonaws.com/PHOTOSHOOTS/APRIL+2023+-+CHELLSEA+ELLE/Adamari10.png",
        },
      ],
      tabInfo: {
        header: "All-Inclusive venue with professional grade production",
        desc: `At Starlight Ballroom, we believe in providing exceptional value for our clients. Our pricing is tailored to accommodate a wide range of budgets, ensuring that you can host your dream event without compromising on quality. Our transparent pricing structure includes flexible packages that can be customized to suit your specific needs. From basic rentals to comprehensive all-inclusive packages, we offer a variety of options that include amenities such as state-of-the-art audiovisual equipment, elegant decor, and professional event staff. With our competitive pricing and extensive range of amenities, you can trust us to deliver an exceptional experience that combines affordability with uncompromising quality.`,
        tabs: [],
        selectedTabId: 0,
        products: []
      },
    };
  },
  async created() {
    const productGroups = await this.actions.group.getGroups({where: {type: "PRODUCT" }, getValues: true});
    this.tabInfo.tabs = productGroups.map(group => {
      return {
        id: group.id,
        label: group.name,
      }
    });

    await this.getProductsList();
  },
  methods: {
    async getProductsList() {
      if (this.tabInfo.tabs.length > 0) {
        const products = await this.actions.product.getProducts({
          getValues: true,
          where: {
            groups_some: {
              id: this.tabInfo.tabs[this.tabInfo.selectedTabId].id
            },
            isActive: true,
          }
        });
        this.tabInfo.products = products.map(product => {
          return {
            img: product.images?.[0]?.source,
            title: product?.name,
            desc: product?.description,
            price: product?.pricing?.[0]?.retailPrice,
            unit: "cup",
          };
        });
      }
    },
    onChangeTab(tabIndex) {
      this.tabInfo.selectedTabId = tabIndex;
      this.getProductsList();
    }
  },
};
</script>

<style scoped></style>
